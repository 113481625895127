import { m } from 'framer-motion';
import styled from 'styled-components';
import LinkWithQuery from '../LinkWithQuery';

export const Container = styled(m.div)`
  min-width: 300px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.sideportal.background};
  user-select: none;
  z-index: 13;
`;

export const Menu = styled(m.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 13;

  ${({ theme }) => theme.breakpoints.xs} {
    padding: 0 1.5em;
  }
  ${({ theme }) => theme.breakpoints.md} {
    padding: 0 2.5em;
  }
`;

export const MotionLinkItems = styled(m.div)`
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  padding-top: 4em;

  ${({ theme }) => theme.breakpoints.lg} {
    padding-top: 0;
  }

  /* Temporary solution for styling MotionLinkItem */
  > a {
    width: fit-content;
    display: block;
    font-weight: 900;
    font-size: 2.25em;
    text-transform: uppercase;
    line-height: 1;

    ${({ theme }) => theme.breakpoints.md} {
      font-size: 3em;
    }

    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.colors.linkHover};
      }
    }
  }
`;

// Styling not being applied, look at MotionLinkIyems as temporary solution.
export const MotionLinkItem = styled(m(LinkWithQuery, { forwardMotionProps: true }))`
  width: fit-content;
  display: block;
  font-weight: 900;
  font-size: 2.5em;
  text-transform: uppercase;
  line-height: 1;

  ${({ theme }) => theme.breakpoints.md} {
    font-size: 3em;
  }

  @media (hover: hover) {
    &:hover {
      color: ${({ theme }) => theme.colors.linkHover};
    }
  }
`;

export const Svg = styled(m.svg)`
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;

  > path {
    &:nth-of-type(1) {
      fill: ${({ theme }) => theme.sideportal.pathOne};
    }

    &:nth-of-type(2) {
      fill: ${({ theme }) => theme.sideportal.pathTwo};
    }

    &:nth-of-type(3) {
      fill: ${({ theme }) => theme.sideportal.pathThree};
    }

    &:nth-of-type(4) {
      fill: ${({ theme }) => theme.sideportal.pathFour};
    }
  }

  &.is-opened {
    pointer-events: auto;
  }
`;
