import React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { SENTRY_IO_ID, getReactEnv, isProduction } from '../../config';

Sentry.init({
  dsn: SENTRY_IO_ID,
  enabled: isProduction(),
  environment: getReactEnv(),
  integrations: [],
  maxBreadcrumbs: 250,
  maxValueLength: 4000,
  tracePropagationTargets: ['localhost', /^https:\/\/rdt-app-new.onrender\.com\/api/],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
});

Sentry.addIntegration(
  new Sentry.BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, ReactRouter.useLocation, ReactRouter.useNavigationType, ReactRouter.createRoutesFromChildren, ReactRouter.matchRoutes),
  }),
);

Sentry.addIntegration(
  new Sentry.Replay({
    maskAllText: false,
    blockAllMedia: false,
  }),
);

export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(ReactRouter.createBrowserRouter);
