export enum Page {
  home = '/',
  notFound = '*',
  about = '/about',
  siteMap = '/sitemap',
  bookingCancellationPolicy = '/booking-cancellation-policy',
  getInTouch = '/get-in-touch',
  treatments = '/treatments',
  treatment = '/treatments/:treatmentId',
  packages = '/packages',
  privacy = '/privacy',
  terms = '/terms',
  cookies = '/cookies',
}

export enum TreatmentType {
  sports = 'sports',
  holistic = 'holistic',
  indianHead = 'indian-head',
  reflexology = 'reflexology',
}

export const pageMapping: Record<Page, string> = {
  [Page.home]: 'Rawand Dilan Therapy',
  [Page.about]: 'RDT | About',
  [Page.bookingCancellationPolicy]: 'RDT | Booking & Cancellation Policy',
  [Page.getInTouch]: 'RDT | Get In Touch',
  [Page.treatments]: 'RDT | Treatments',
  [Page.treatment]: 'RDT | Treatment',
  [Page.notFound]: 'RDT | 404',
  [Page.siteMap]: 'RDT | Site Map',
  [Page.packages]: 'RDT | Packages',
  [Page.privacy]: 'RDT | Privacy Policy',
  [Page.terms]: 'RDT | Terms & Conditions',
  [Page.cookies]: 'RDT | Cookie Policy',
};

export const treatmentPageMapping: Record<TreatmentType, string> = {
  [TreatmentType.sports]: 'RDT | Sports',
  [TreatmentType.holistic]: 'RDT | Holistic',
  [TreatmentType.indianHead]: 'RDT | Indian Head',
  [TreatmentType.reflexology]: 'RDT | Reflexology',
};

export const headerPageMapping: Record<Page, string> = {
  [Page.home]: 'Home',
  [Page.about]: 'About',
  [Page.bookingCancellationPolicy]: 'Booking Policy',
  [Page.getInTouch]: 'Get In Touch',
  [Page.treatments]: 'Treatments',
  [Page.treatment]: 'Treatment',
  [Page.notFound]: '404',
  [Page.siteMap]: 'Sitemap',
  [Page.packages]: 'Packages',
  [Page.privacy]: 'Privacy Policy',
  [Page.terms]: 'Terms & Conditions',
  [Page.cookies]: 'Cookie Policy',
};

export const PAGES: Page[] = Object.values(Page);

export type IPages = keyof typeof Page;

export const pageTitles: Record<Page, string> = {
  [Page.home]: 'screens.home.title',
  [Page.about]: 'screens.about.title',
  [Page.bookingCancellationPolicy]: 'screens.bookingCancellationPolicy.title',
  [Page.getInTouch]: 'screens.getInTouch.title',
  [Page.treatments]: 'screens.treatments.title',
  [Page.treatment]: 'screens.treatment.title',
  [Page.notFound]: 'screens.notFound.title',
  [Page.siteMap]: 'screens.siteMap.title',
  [Page.packages]: 'screens.packages.title',
  [Page.privacy]: 'screens.privacy.title',
  [Page.terms]: 'screens.terms.title',
  [Page.cookies]: 'screens.cookies.title',
};

export const commonExcludedRoutes: Page[] = [Page.notFound, Page.treatment, Page.cookies, Page.privacy, Page.terms];

export const footerExcludedMainRoutes: Page[] = [...commonExcludedRoutes, Page.bookingCancellationPolicy, Page.getInTouch, Page.siteMap];

export const footerExcludedCompanyRoutes: Page[] = [...commonExcludedRoutes, Page.home, Page.treatments, Page.about, Page.packages];

export const copyrightRoutes: Page[] = [Page.privacy, Page.cookies, Page.terms];

export const morphingSidePortalRoutes: Page[] = [...commonExcludedRoutes, Page.siteMap];

export const headerVariantRoutes: Page[] = [Page.about, Page.getInTouch, Page.bookingCancellationPolicy, Page.siteMap, Page.treatment, Page.treatments];
