import { InitOptions, Resource, ResourceLanguage } from 'i18next';
import { arrayFromKeys } from '@raiden-corp/rc-kit/utils';
import { CountryCodeIso31661 } from '../types/CountryCodeIso31661';
import { getIsCountryIntl } from '../utils/translation/CountryCodeIso31661';
import resources from './resources';

export const defaultNS = 'ns1';
export const fallbackLng = 'en';

const languagePaths = ['en', 'fr'] as const;

arrayFromKeys(CountryCodeIso31661).map(countryCode => {
  languagePaths.forEach(language => {
    const locale = language;
    const en = resources['en'];
    const fr = resources['fr'];
    let translationObj;

    if (language === 'en') {
      const countryBasedLang: typeof en = en;
      translationObj = getIsCountryIntl(countryCode) ? en : countryBasedLang;
    } else if (language === 'fr') {
      translationObj = fr;
    }

    (resources as Resource)[locale] = translationObj as ResourceLanguage;
  });
});

export const options: InitOptions = {
  ns: ['ns1'],
  defaultNS,
  fallbackLng,
  keySeparator: '.',
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['localStorage', 'path', 'querystring'],
    lookupQuerystring: 'lang',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    lookupLocalStorage: 'lang',
  },
  returnObjects: true,
  supportedLngs: ['en', 'fr'],
  resources,
};
