import { AnimatePresence, AnimatePresenceProps } from 'framer-motion';

const FramerPresence = <T extends AnimatePresenceProps & React.PropsWithChildren>({ mode = 'wait', children, ...rest }: T) => {
  return (
    <AnimatePresence mode={mode} {...rest}>
      {children}
    </AnimatePresence>
  );
};

export default FramerPresence;
