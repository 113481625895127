import { Component, ErrorInfo } from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import * as S from './ErrorBoundary.styles';
import { CUSTOMER_SERVICE_EMAIL, isProduction } from '../../config';
import { ErrorBoundaryProps, ErrorBoundaryState } from './ErrorBoundary';
import { Button, ILogo, Typography } from '@raiden-corp/rc-kit/components';
import { getTextWithLink } from '@raiden-corp/rc-kit/utils';
import Logo from '../../components/Logo';
import { useResponsive } from '@raiden-corp/rc-kit/hooks';
import { CustomBreakPoint } from '@raiden-corp/rc-kit/common';

const RootErrorBoundary = () => {
  const { t } = useTranslation();
  const error = useRouteError() as Error;
  const { findAppliedBreakpointVal } = useResponsive();
  const copy = t('components.errorBoundary');

  const logoVariants: Record<CustomBreakPoint, ILogo['size']> = {
    xs: 'xs',
    sm: 'sm',
    md: 'md',
    lg: 'lg',
    xl: 'xl',
    xxl: 'xxl',
  };

  return (
    <S.Container data-testid="root-error-boundary">
      <div>
        <Logo size={findAppliedBreakpointVal(logoVariants)} />
      </div>
      <div>
        <Typography tag="h1" variant="d1">
          {copy.rootError.title}
        </Typography>
        <Typography tag="h2" variant="p2" weight="300">
          {copy.rootError.subtitle}
        </Typography>
        <div>
          <Typography tag="h3" variant="p3">
            {copy.rootError.info.title}
          </Typography>
          <ul>
            {copy.rootError.info.p.map((p, i) => (
              <li key={i}>
                <Typography key={i} tag="p" variant="p6">
                  {p}
                </Typography>
              </li>
            ))}
          </ul>
        </div>
        {!isProduction() && (
          <div className="debug">
            <code>{`Error`}</code> {error.message || JSON.stringify(error)}
          </div>
        )}
        <Button id="root-error-boundary" onClick={() => (window.location.href = '/')}>
          {copy.rootError.button.toUpperCase()}
        </Button>
      </div>
    </S.Container>
  );
};

const ProjectErrorBoundary = () => {
  const { t } = useTranslation();
  const error = useRouteError() as Error;
  const copy = t('components.errorBoundary');

  /* We only care to handle 401's at this level, so if this is not a 401 */
  /* ErrorResponse, re-throw to let the RootErrorBoundary handle it */
  if (!isRouteErrorResponse(error) || error.status !== 401) {
    throw error;
  }

  return (
    <S.Container data-testid="project-error-boundary">
      <Typography tag="h1" variant="d5">
        {copy.projectError.title}
      </Typography>
      <p>{getTextWithLink(copy.projectError.p, CUSTOMER_SERVICE_EMAIL)}</p>
      <Button id="project-error-boundary" onClick={() => (window.location.href = '/')}>
        {copy.back}
      </Button>
    </S.Container>
  );
};

const Fallback = () => {
  return <Typography tag="h1" variant="p5">{`Performing initial data "load"`}</Typography>;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = {
    error: '',
    hasError: false,
  };

  copy = this.props.t('components.errorBoundary');

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  componentDidCatch(e: Error, errorInfo: ErrorInfo) {
    if (isProduction()) console.log(`error caught in error boundery: ${e.message}. Trace stack: ${errorInfo.componentStack}`);
    else console.log('error caught in error boundary', { e, errorInfo });
    this.setState({ error: e.message });
  }

  onReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <>
          <S.Container data-testid="errorboundary">
            <Typography tag="h1" variant="d5" data-testid="errorboundary-message">
              {this.copy.message}
            </Typography>
            <Button id="errorBoundary" onClick={this.onReload}>
              {this.copy.reload}
            </Button>
          </S.Container>
        </>
      );
    }
    return <>{this.props.children}</>;
  }
}

export default withTranslation()(ErrorBoundary);
export { RootErrorBoundary, ProjectErrorBoundary, Fallback };
