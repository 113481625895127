import { AnimationProps } from 'framer-motion';

export const mainVariants: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: 'spring',
    duration: 0.5,
    delay: 0.25,
  },
};
