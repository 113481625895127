export const tryCreateModalRoot = (rootId: string) => {
  const root = document.getElementById(rootId);

  if (!root) {
    const el = document.createElement('div');
    el.id = rootId;
    document.body.append(el);
    return el;
  }

  return root;
};
