import { SupportedLanguage } from './SupportedLanguage';

export enum CountryCodeIso31661 {
  UnitedKingdom = 'gb',
  France = 'fr',
}

export const isCountryIntl: Record<CountryCodeIso31661, boolean> = {
  [CountryCodeIso31661.France]: false,
  [CountryCodeIso31661.UnitedKingdom]: true,
};

export const countryToDefaultLang: Record<CountryCodeIso31661, SupportedLanguage> = {
  [CountryCodeIso31661.France]: SupportedLanguage.English,
  [CountryCodeIso31661.UnitedKingdom]: SupportedLanguage.English,
};
