import { css } from 'styled-components';

const NeoSansBlackEot = new URL('/fonts/neo-sans/NeoSans-Black.eot', import.meta.url).pathname;
const NeoSansBlackTtf = new URL('/fonts/neo-sans/NeoSans-Black.ttf', import.meta.url).pathname;
const NeoSansBlackWoff = new URL('/fonts/neo-sans/NeoSans-Black.woff', import.meta.url).pathname;
const NeoSansBlackWoff2 = new URL('/fonts/neo-sans/NeoSans-Black.woff2', import.meta.url).pathname;
const NeoSansBoldEot = new URL('/fonts/neo-sans/NeoSans-Bold.eot', import.meta.url).pathname;
const NeoSansBoldTtf = new URL('/fonts/neo-sans/NeoSans-Bold.ttf', import.meta.url).pathname;
const NeoSansBoldWoff = new URL('/fonts/neo-sans/NeoSans-Bold.woff', import.meta.url).pathname;
const NeoSansBoldWoff2 = new URL('/fonts/neo-sans/NeoSans-Bold.woff2', import.meta.url).pathname;
const NeoSansMediumEot = new URL('/fonts/neo-sans/NeoSans-Medium.eot', import.meta.url).pathname;
const NeoSansMediumTtf = new URL('/fonts/neo-sans/NeoSans-Medium.ttf', import.meta.url).pathname;
const NeoSansMediumWoff = new URL('/fonts/neo-sans/NeoSans-Medium.woff', import.meta.url).pathname;
const NeoSansMediumWoff2 = new URL('/fonts/neo-sans/NeoSans-Medium.woff2', import.meta.url).pathname;
const NeoSansLightEot = new URL('/fonts/neo-sans/NeoSans-Light.eot', import.meta.url).pathname;
const NeoSansLightTtf = new URL('/fonts/neo-sans/NeoSans-Light.ttf', import.meta.url).pathname;
const NeoSansLightWoff = new URL('/fonts/neo-sans/NeoSans-Light.woff', import.meta.url).pathname;
const NeoSansLightWoff2 = new URL('/fonts/neo-sans/NeoSans-Light.woff2', import.meta.url).pathname;
const NeoSansEot = new URL('/fonts/neo-sans/NeoSans.eot', import.meta.url).pathname;
const NeoSansTtf = new URL('/fonts/neo-sans/NeoSans.ttf', import.meta.url).pathname;
const NeoSansWoff = new URL('/fonts/neo-sans/NeoSans.woff', import.meta.url).pathname;
const NeoSansWoff2 = new URL('/fonts/neo-sans/NeoSans.woff2', import.meta.url).pathname;

export const fontFace = css`
  /* BLACK */
  @font-face {
    font-family: 'NeoSans';
    src: url(${NeoSansBlackEot});
    src:
      local('NeoSans Black'),
      local('NeoSans-Black'),
      url('NeoSans-Black.eot?#iefix') format('embedded-opentype'),
      url(${NeoSansBlackWoff2}) format('woff2'),
      url(${NeoSansBlackWoff}) format('woff'),
      url(${NeoSansBlackTtf}) format('truetype');
    font-weight: 900;
    font-style: normal;
  }

  /* BOLD */
  @font-face {
    font-family: 'NeoSans';
    src: url(${NeoSansBoldEot});
    src:
      local('NeoSans Bold'),
      local('NeoSans-Bold'),
      url('NeoSansBold.eot?#iefix') format('embedded-opentype'),
      url(${NeoSansBoldWoff2}) format('woff2'),
      url(${NeoSansBoldWoff}) format('woff'),
      url(${NeoSansBoldTtf}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  /* MEDIUM */
  @font-face {
    font-family: 'NeoSans';
    src: url(${NeoSansMediumEot});
    src:
      local('NeoSans Medium'),
      local('NeoSans-Medium'),
      url('NeoSans-Medium.eot?#iefix') format('embedded-opentype'),
      url(${NeoSansMediumWoff2}) format('woff2'),
      url(${NeoSansMediumWoff}) format('woff'),
      url(${NeoSansMediumTtf}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  /* LIGHT */
  @font-face {
    font-family: 'NeoSans';
    src: url(${NeoSansLightEot});
    src:
      local('NeoSans Light'),
      local('NeoSans-Light'),
      url('NeoSans-Light.eot?#iefix') format('embedded-opentype'),
      url(${NeoSansLightWoff2}) format('woff2'),
      url(${NeoSansLightWoff}) format('woff'),
      url(${NeoSansLightTtf}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  /* REGULAR */
  @font-face {
    font-family: 'NeoSans';
    src: url(${NeoSansEot});
    src:
      local('NeoSans'),
      url(${NeoSansWoff2}) format('woff2'),
      url(${NeoSansWoff}) format('woff'),
      url(${NeoSansTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
