import { createGlobalStyle, css } from 'styled-components';
import { ThemeInterface } from '../themes';
import { fontFace } from './fontFace';
import { core, navigation, pagination, a11y, effectCards } from './swiperStyles';
import { darkTheme, lightTheme } from './CookieConsentStyles';

export interface GlobalThemeProps {
  theme: ThemeInterface;
}

const rootStyles = css`
  --margin: 0 auto;
  --padding: 3em 2em;
  --border-radius: 2em;
  --overlay-light: rgba(238, 228, 210, 15%);
  --overlay-dark: rgba(37, 35, 33, 45%);

  --vector-fill-light: #121212;
  --vector-fill-dark: #eee4d2;

  --gold-50: #ffd700;
  --beige-50: #eee4d2;
  --grey-50: #1f1e1c;
  --treatwell: #ff7457;
  --facebook: #395793;

  --box-shadow-color-dark-1: rgba(238, 228, 210, 0.4);
  --box-shadow-color-dark-2: rgba(233, 221, 199, 0.4);
  --box-shadow-color-light-1: rgba(37, 35, 33, 0.5);
  --box-shadow-color-light-2: rgba(27, 26, 24, 0.5);
  --box-shadow: 0px 0px 6px rgba(238, 228, 210, 0.16), 0px 0px 6px rgba(214, 205, 189, 0.23);

  --black-light-50: #ebdfcc;
  --black-light-100: #1f1e1c;

  --black-dark-50: #1f1e1c;
  --black-dark-100: #eee4d2;

  /* SWIPER */
  --swiper-theme-color-dark: #eee4d2;
  --swiper-theme-color-light: #121212;

  --swiper-theme-color-override-dark: #121212;
  --swiper-theme-color-override-light: #eee4d2;

  --swiper-navigation-size: 22px !important;
  --swiper-navigation-top-offset: 7%;
  --swiper-navigation-sides-offset: 47.5%;
  --swiper-pagination-bullet-horizontal-gap: 8px;
  --swiper-pagination-bullet-size: 10px;

  /* MORPHISM */
  --morphism-svg-top-light: #eee4d2;
  --morphism-svg-top-dark: #1f1e1c;

  --morphism-svg-bottom-light: #1f1e1c;
  --morphism-svg-bottom-dark: #eee4d2;

  --morphism-background-light: #ebdfcc;
  --morphism-background-dark: #1f1e1c;

  --morphism-fill-one-light: #b6aa97;
  --morphism-fill-two-light: #cbbca4;
  --morphism-fill-three-light: #e2d1b6;
  --morphism-fill-four-light: #ebdfcc;

  --morphism-fill-one-dark: #393631;
  --morphism-fill-two-dark: #2f2d29;
  --morphism-fill-three-dark: #262420;
  --morphism-fill-four-dark: #1f1e1c;

  --morph-svg-bg-color-light: #eee4d2;
  --morph-svg-bg-color-dark: #1f1e1c;

  --svg-path-fill-one-light: #d6cdbd;
  --svg-path-fill-two-light: #beb6a8;
  --svg-path-fill-three-light: #a7a093;
  --svg-path-fill-four-light: #8f897e;
  --svg-path-fill-five-light: #777269;
  --svg-path-fill-six-light: #5f5b54;
  --svg-path-fill-seven-light: #47443f;

  --svg-path-fill-one-dark: #252321;
  --svg-path-fill-two-dark: #2b2926;
  --svg-path-fill-three-dark: #302e2b;
  --svg-path-fill-four-dark: #363430;
  --svg-path-fill-five-dark: #3c3935;
  --svg-path-fill-six-dark: #413f3a;
  --svg-path-fill-seven-dark: #47443f;

  /* SIDE PORTAL */
  --path-fill-1-dark: #e2d1b6;
  --path-fill-2-dark: #aa9d89;
  --path-fill-3-dark: #6f6659;
  --path-fill-4-dark: #262420;

  --path-fill-1-light: #cbbca4;
  --path-fill-2-light: #e2d1b6;
  --path-fill-3-light: #b7a994;
  --path-fill-4-light: #ebdfcc;
`;

const colorPalette = css`
  --primary-50: #fdfcfa;
  --primary-100: #faf7f2;
  --primary-200: #f7f2e9;
  --primary-300: #f3ece0;
  --primary-400: #f1e8d9;
  --primary-500: #eee4d2;
  --primary-600: #ece1cd;
  --primary-700: #e9ddc7;
  --primary-800: #e7d9c1;
  --primary-900: #e2d1b6;
  --primary-A100: #ffffff;
  --primary-A200: #ffffff;
  --primary-A400: #ffffff;
  --primary-A700: #ffffff;

  --secondary-50: #e5e5e4;
  --secondary-100: #bebdbc;
  --secondary-200: #929190;
  --secondary-300: #666564;
  --secondary-400: #464442;
  --secondary-500: #252321;
  --secondary-600: #211f1d;
  --secondary-700: #1b1a18;
  --secondary-800: #161514;
  --secondary-900: #0d0c0b;
  --secondary-A100: #ff5454;
  --secondary-A200: #ff2121;
  --secondary-A400: #ed0000;
  --secondary-A700: #d40000;

  --warning-50: #fdede1;
  --warning-100: #fad3b3;
  --warning-200: #f6b681;
  --warning-300: #f2984e;
  --warning-400: #f08228;
  --warning-500: #ed6c02;
  --warning-600: #eb6402;
  --warning-700: #e85901;
  --warning-800: #e54f01;
  --warning-900: #e03d01;
  --warning-A100: #ffffff;
  --warning-A200: #ffddd4;
  --warning-A400: #ffb5a1;
  --warning-A700: #ffa088;

  --danger-50: #fae6e6;
  --danger-100: #f2c1c1;
  --danger-200: #e99797;
  --danger-300: #e06d6d;
  --danger-400: #da4e4e;
  --danger-500: #d32f2f;
  --danger-600: #ce2a2a;
  --danger-700: #c82323;
  --danger-800: #c21d1d;
  --danger-900: #b71212;
  --danger-A100: #ffe7e7;
  --danger-A200: #ffb4b4;
  --danger-A400: #ff8181;
  --danger-A700: #ff6868;

  --success-50: #e8f5e9;
  --success-100: #c8e6c9;
  --success-200: #a5d6a7;
  --success-300: #81c784;
  --success-400: #66bb6a;
  --success-500: #4caf50;
  --success-600: #43a047;
  --success-700: #388e3c;
  --success-800: #2e7d32;
  --success-900: #1b5e20;
  --success-A100: #b9f6ca;
  --success-A200: #69f0ae;
  --success-A400: #00e676;
  --success-A700: #00c853;

  --shark-50: #f4f4f4;
  --shark-100: #e9e9e9;
  --shark-200: #c9c8c8;
  --shark-300: #a8a7a6;
  --shark-400: #666564;
  --shark-500: #252321;
  --shark-600: #21201e;
  --shark-700: #1c1a19;
  --shark-800: #161514;
  --shark-900: #121110;
  --shark-A100: #121212;
  --shark-A200: #121212;
  --shark-A400: #121212;
  --shark-A700: #121212;

  --albescent-white-dark-50: #fefefd;
  --albescent-white-dark-100: #fdfcfb;
  --albescent-white-dark-200: #fbf8f4;
  --albescent-white-dark-300: #f8f4ed;
  --albescent-white-dark-400: #f3ece0;
  --albescent-white-dark-500: #eee4d2;
  --albescent-white-dark-600: #d6cdbd;
  --albescent-white-dark-700: #b3ab9e;
  --albescent-white-dark-800: #8f897e;
  --albescent-white-dark-900: #757067;
  --albescent-white-dark-A100: #ffffff;
  --albescent-white-dark-A200: #ffffff;
  --albescent-white-dark-A400: #ffffff;
  --albescent-white-dark-A700: #ffffff;

  --albescent-white-light-50: #f4f4f4;
  --albescent-white-light-100: #e9e9e9;
  --albescent-white-light-200: #c9c8c8;
  --albescent-white-light-300: #a8a7a6;
  --albescent-white-light-400: #666564;
  --albescent-white-light-500: #252321;
  --albescent-white-light-600: #21201e;
  --albescent-white-light-700: #1c1a19;
  --albescent-white-light-800: #161514;
  --albescent-white-light-900: #121110;
  --albescent-white-light-A100: #121212;
  --albescent-white-light-A200: #121212;
  --albescent-white-light-A400: #121212;
  --albescent-white-light-A700: #121212;
`;

export const GlobalStyle = createGlobalStyle`
  ${fontFace};

  ${core}
  ${a11y}
  ${navigation}
  ${pagination}
  ${effectCards}

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
    ${rootStyles};
    ${colorPalette};
    ${lightTheme};
  }

  ${darkTheme};

  html {
    scroll-behavior: smooth;
  }

  body {
    font-weight: ${({ theme }) => theme.typography.fontWeights.body};
    font-size: 1em;
    font-family: ${({ theme }) => theme.typography.fontFamilies.neoSans.regular};
    line-height: 1.4;
    color: ${({ theme }) => theme.colors.text};
    background-color: ${({ theme }) => theme.body.backgrounds[50]};
    -webkit-tap-highlight-color: transparent;
    -webkit-overflow-scrolling: touch;
  }

  main {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  menu, ol, ul {
    list-style: disc;
  }
  
  a {
    color: ${({ theme }) => theme.colors.text};
    text-decoration: none;

    @media(hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.colors.linkHover};
      }
    }
  }

  p {
    color: ${({ theme }) => theme.colors.text};
  }
`;

const GlobalStyles = () => <GlobalStyle />;

export default GlobalStyles;
