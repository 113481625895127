import { useTranslation } from 'react-i18next';
import * as S from './SimpleLogo.styles';

const SimpleLogo = () => {
  const { t } = useTranslation();
  const copy = t('components.footer');

  return (
    <S.Wrapper data-testid="simple-logo">
      <S.Container>
        <svg width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path d="M50,250c0-110.5,89.5-200,200-200s200,89.5,200,200s-89.5,200-200,200S50,360.5,50,250" />
          </defs>
          <S.Circle cx="40" cy="40" r="30" />
          <S.Text x="52%" y="53%" textAnchor="middle" textLength="45" dy=".3em">
            RDT
          </S.Text>
        </svg>
      </S.Container>
      <S.Title>{copy.logo.title}</S.Title>
    </S.Wrapper>
  );
};

export default SimpleLogo;
