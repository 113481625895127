import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import setupAxiosInterceptors from './axios/AxiosInterceptors';
import axiosInstance from './axios/AxiosInstance';
import reportWebVitals from './reportWebVitals';
import disableReactDevTools from './utils/generic/disableReactDevTools';
import reportAccessibility from './utils/generic/reportAccessibility';
import { initializeCookieConsent } from './utils/analytics/initializeCookieConsent';

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  disableReactDevTools();
}

function render() {
  const container = document.getElementById('root');
  if (!container) return null;
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
}

(() => {
  setupAxiosInterceptors(axiosInstance);
  isProduction && initializeCookieConsent();
  render();
  reportAccessibility(React);
})();

reportWebVitals();
