import { m } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(m.div)`
  padding: 1em;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary[500]};
  background-color: ${({ theme }) => theme.colors.secondary[500]};
  box-shadow: 2px 3px 3px 0 ${({ theme }) => theme.body.boxShadows[100]};

  ${({ theme }) => theme.breakpoints.lg} {
    padding: 0.75em 1em;
  }
`;

export const ToastContent = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5em;
`;

export const Message = styled.div`
  flex: 1 auto;
`;

export const DebugButtons = styled.div`
  display: flex;
  flex-flow: row wrap;
  place-content: center;
  gap: 0.25em;
`;

export const Date = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 1em;
  padding-top: 1em;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[500] + '7a'};
`;

export const Buttons = styled.div.withConfig({
  shouldForwardProp: props => !['offlineReady'].includes(props),
})<{ offlineReady: boolean }>`
  display: flex;
  flex-flow: row wrap;
  gap: 0.5em;
  padding-top: 1em;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[500] + '7a'};

  button {
    &:first-child {
      margin-right: ${({ offlineReady }) => (offlineReady ? 'auto' : '0.5em')};
    }
  }
`;
