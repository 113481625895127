export enum SupportedLanguage {
  English = 'en',
  French = 'fr',
}

export const languageToLanguageName: Record<SupportedLanguage, string> = {
  [SupportedLanguage.English]: 'EN',
  [SupportedLanguage.French]: 'FR',
};

export const languageToLanguageShortCode: Record<SupportedLanguage, string> = {
  [SupportedLanguage.English]: 'en',
  [SupportedLanguage.French]: 'fr',
};

export function isValidLanguage(str: string): str is SupportedLanguage {
  return Object.values(SupportedLanguage).includes(str as SupportedLanguage);
}
