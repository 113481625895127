import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-area: logo;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 1em;
  letter-spacing: 1px;
`;

export const Container = styled.div`
  position: relative;
`;

export const Circle = styled.circle`
  fill: ${({ theme }) => theme.body.backgrounds[100]};
`;

export const Text = styled.text`
  fill: ${({ theme }) => theme.body.backgrounds[50]};
  font-weight: 500;
  font-size: 1.5em;
`;
