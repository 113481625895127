import { lightTheme, darkTheme, ThemeInterface } from '../themes';

export enum IThemeNames {
  light = 'light',
  dark = 'dark',
}

export type ThemeMap = Record<IThemeNames, ThemeInterface>;

export const themesMap: ThemeMap = {
  [IThemeNames.light]: lightTheme,
  [IThemeNames.dark]: darkTheme,
};
