import { IThemeProps, lightTheme as lightColors, darkTheme as darkColors } from '@raiden-corp/rc-kit/common';

export interface ThemeInterface extends IThemeProps {
  colors: IThemeProps['colors'] & {
    linkOverride: string;
    linkHover: string;
  };
  body: {
    padding: string;
    borderRadius: string;
    backgrounds: {
      50: string;
      100: string;
    };
    boxShadows: {
      50: string;
      100: string;
    };
    overlay: string;
  };
  icons: {
    vector: string;
  };
  sideportal: {
    background: string;
    pathOne: string;
    pathTwo: string;
    pathThree: string;
    pathFour: string;
  };
  morphism: {
    background: string;
    fill: {
      one: string;
      two: string;
      three: string;
      four: string;
    };
    svg: {
      top: string;
      bottom: string;
    };
    morphSvgBgColor: string;
    svgPathFillOne: string;
    svgPathFillTwo: string;
    svgPathFillThree: string;
    svgPathFillFour: string;
    svgPathFillFive: string;
    svgPathFillSix: string;
    svgPathFillSeven: string;
  };
  swiper: {
    navigation: {
      default: string;
      override: string;
    };
    pagination: {
      default: string;
      override: string;
    };
  };
}

export interface ITheme {
  theme: ThemeInterface;
}

export const lightTheme: ThemeInterface = {
  ...lightColors,
  colors: {
    ...lightColors.colors,
    linkOverride: 'var(--primary-500)',
    linkHover: 'var(--secondary-400)',
  },
  body: {
    padding: 'var(--padding)',
    borderRadius: 'var(--border-radius)',
    backgrounds: {
      50: 'var(--black-light-50)',
      100: 'var(--black-light-100)',
    },
    boxShadows: {
      50: 'var(--box-shadow-color-light-1)',
      100: 'var(--box-shadow-color-light-2)',
    },
    overlay: 'var(--overlay-light)',
  },
  icons: {
    vector: 'var(--vector-fill-light)',
  },
  sideportal: {
    background: 'var(--black-light-50)',
    pathOne: 'var(--path-fill-1-light)',
    pathTwo: 'var(--path-fill-2-light)',
    pathThree: 'var(--path-fill-3-light)',
    pathFour: 'var(--path-fill-4-light)',
  },
  morphism: {
    background: 'var(--morphism-background-light)',
    fill: {
      one: 'var(--morphism-fill-one-light)',
      two: 'var(--morphism-fill-two-light)',
      three: 'var(--morphism-fill-three-light)',
      four: 'var(--morphism-fill-four-light)',
    },
    svg: {
      top: 'var(--morphism-svg-top-light)',
      bottom: 'var(--morphism-svg-bottom-light)',
    },
    morphSvgBgColor: 'var(--morph-svg-bg-color-light)',
    svgPathFillOne: 'var(--svg-path-fill-one-light)',
    svgPathFillTwo: 'var(--svg-path-fill-two-light)',
    svgPathFillThree: 'var(--svg-path-fill-three-light)',
    svgPathFillFour: 'var(--svg-path-fill-four-light)',
    svgPathFillFive: 'var(--svg-path-fill-five-light)',
    svgPathFillSix: 'var(--svg-path-fill-six-light)',
    svgPathFillSeven: 'var(--svg-path-fill-seven-light)',
  },
  swiper: {
    navigation: {
      default: 'var(--swiper-theme-color-light)',
      override: 'var(--swiper-theme-color-override-light)',
    },
    pagination: {
      default: 'var(--swiper-theme-color-light)',
      override: 'var(--swiper-theme-color-override-light)',
    },
  },
};

export const darkTheme: ThemeInterface = {
  ...darkColors,
  colors: {
    ...darkColors.colors,
    linkOverride: 'var(--secondary-500)',
    linkHover: 'var(--primary-900)',
  },
  body: {
    ...lightTheme.body,
    backgrounds: {
      50: 'var(--black-dark-50)',
      100: 'var(--black-dark-100)',
    },
    boxShadows: {
      50: 'var(--box-shadow-color-dark-1)',
      100: 'var(--box-shadow-color-dark-2)',
    },
    overlay: 'var(--overlay-dark)',
  },
  icons: {
    vector: 'var(--vector-fill-dark)',
  },
  sideportal: {
    background: 'var(--black-dark-50)',
    pathOne: 'var(--path-fill-1-dark)',
    pathTwo: 'var(--path-fill-2-dark)',
    pathThree: 'var(--path-fill-3-dark)',
    pathFour: 'var(--path-fill-4-dark)',
  },
  morphism: {
    background: 'var(--morphism-background-dark)',
    fill: {
      one: 'var(--morphism-fill-one-dark)',
      two: 'var(--morphism-fill-two-dark)',
      three: 'var(--morphism-fill-three-dark)',
      four: 'var(--morphism-fill-four-dark)',
    },
    svg: {
      top: 'var(--morphism-svg-top-dark)',
      bottom: 'var(--morphism-svg-bottom-dark)',
    },
    morphSvgBgColor: 'var(--morph-svg-bg-color-dark)',
    svgPathFillOne: 'var(--svg-path-fill-one-dark)',
    svgPathFillTwo: 'var(--svg-path-fill-two-dark)',
    svgPathFillThree: 'var(--svg-path-fill-three-dark)',
    svgPathFillFour: 'var(--svg-path-fill-four-dark)',
    svgPathFillFive: 'var(--svg-path-fill-five-dark)',
    svgPathFillSix: 'var(--svg-path-fill-six-dark)',
    svgPathFillSeven: 'var(--svg-path-fill-seven-dark)',
  },
  swiper: {
    navigation: {
      default: 'var(--swiper-theme-color-dark)',
      override: 'var(--swiper-theme-color-override-dark)',
    },
    pagination: {
      default: 'var(--swiper-theme-color-dark)',
      override: 'var(--swiper-theme-color-override-dark)',
    },
  },
};
