import * as S from './Footer.styles';
import SimpleLogo from './SimpleLogo';
import NavLinks from './NavLinks';
import Copyright from './Copyright';

const Footer = () => {
  return (
    <S.Container data-testid="footer">
      <SimpleLogo />
      <NavLinks />
      <Copyright />
    </S.Container>
  );
};

export default Footer;
