import styled from 'styled-components';

export const Container = styled.button`
  z-index: 3;
  display: flex;
  border: none;
  position: relative;
  background: transparent;

  &:focus,
  &:focus-visible {
    outline-offset: 5px;
  }
`;
