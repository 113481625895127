import { appendScript, appendScriptText } from '../../generic/appendScript';

export function injectGoogleAnalytics(id: string) {
  appendScript(`https://www.googletagmanager.com/gtag/js?id=${id}`, 500);
  const script = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', '${id}');
  `;
  appendScriptText(script, 1000);
}
