import { ISpinner, Spinner } from '@raiden-corp/rc-kit/components';
import { useAnimationFlag, useThemeMode } from '@raiden-corp/rc-kit/hooks';
import { useReducedMotion } from 'framer-motion';
import { themesMap } from '../../types/Theme';

const SpinnerOfDoom = () => {
  const { theme } = useThemeMode();
  const prefersReducedMotion = useReducedMotion();
  const { isAnimationsDisabled } = useAnimationFlag(prefersReducedMotion);

  const spinnerProps: ISpinner = {
    innerText: 'RDT',
    outerText: 'Rawand Dilan Therapy',
    size: 'xxl',
    withAnimation: !isAnimationsDisabled,
    classes: 'SpinnerOfDoom',
    styles: {
      backgroundColor: themesMap[theme].colors.secondary[500],
    },
  };
  return <Spinner {...spinnerProps} />;
};

export default SpinnerOfDoom;
