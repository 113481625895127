import { MotionProps } from 'framer-motion';
import * as S from './MenuLogo.styles';
import { IMenuLogo, menuLogoVariants } from './MenuLogo';
import FramerPresence from '../../hoc/FramerPresence';
import { Page } from '../../types/Page';
import Logo from '../Logo';
import { ILinkWithQuery } from '../LinkWithQuery';

const MenuLogo = <T extends boolean>({ isOpen }: IMenuLogo<T>) => {
  const linkProps: React.AnchorHTMLAttributes<HTMLAnchorElement> & ILinkWithQuery & MotionProps & { 'data-testid': string; key: string } = {
    to: Page.home,
    'aria-label': 'RDT Logo',
    'data-testid': 'menu-logo-link',
    key: 'menu-logo-link',
    variants: menuLogoVariants,
    initial: 'visible',
    animate: isOpen ? 'hidden' : 'visible',
  };

  return (
    <FramerPresence>
      <S.LogoLink {...linkProps}>
        <Logo size="xs" />
      </S.LogoLink>
    </FramerPresence>
  );
};

export default MenuLogo;
