import { Variants } from 'framer-motion';

const customEase = [0.455, 0.03, 0.515, 0.955];

export const initialVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: customEase,
      duration: 0.6,
      delay: 2.5,
    },
  },
  hidden: {
    opacity: 0,
    y: -5,
    transition: {
      ease: customEase,
      duration: 0.6,
      delay: 0.6,
    },
  },
};

export const standardVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: customEase,
      duration: 0.6,
      delay: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: -5,
    transition: {
      ease: customEase,
      duration: 0.6,
      delay: 0.6,
    },
  },
};

export const mobileVariants: Variants = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: customEase,
      duration: 0.6,
    },
  },
  hidden: {
    opacity: 0,
    y: -5,
    transition: {
      ease: customEase,
      duration: 0.6,
      delay: 0.6,
    },
  },
};
