import styled from 'styled-components';

export const Container = styled.footer`
  position: relative;
  display: grid;
  place-content: center center;
  padding: 1em 1em 0;

  ${({ theme }) => theme.breakpoints.xs} {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      'navlinks'
      'logo'
      'copyright';
    grid-gap: 2em;
    padding: 3em 0 0;
  }

  ${({ theme }) => theme.breakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 150px 100px;
    grid-template-areas:
      'logo navlinks navlinks'
      'copyright copyright copyright';
  }
`;
