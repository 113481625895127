import Cookies from 'js-cookie';

import { Cookie } from '../../types/Cookie';
import { DEFAULT_COUNTRY } from '../../config';
import { SupportedLanguage } from '../../types/SupportedLanguage';
import { arrayFromKeys } from '@raiden-corp/rc-kit/utils';
import { CountryCodeIso31661, countryToDefaultLang, isCountryIntl } from '../../types/CountryCodeIso31661';

export function isSupportedCountry(str: string | CountryCodeIso31661): str is CountryCodeIso31661 {
  return arrayFromKeys(CountryCodeIso31661).includes(str as CountryCodeIso31661);
}

export function getDefaultLangForCountry(country: CountryCodeIso31661): SupportedLanguage {
  return countryToDefaultLang[country];
}

export function getIsCountryIntl(country: CountryCodeIso31661): boolean {
  return isCountryIntl[country];
}

export function getCurrentCountry(): CountryCodeIso31661 {
  return (Cookies.get(Cookie.currentCountry) as CountryCodeIso31661) || DEFAULT_COUNTRY;
}
