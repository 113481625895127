import { m } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled(m.header)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 14;
  min-height: 79px;

  ${({ theme }) => theme.breakpoints.xs} {
    padding: 1.6em 1.5em;
  }
  ${({ theme }) => theme.breakpoints.md} {
    padding: 1.6em 2.5em;
  }
`;

export const Container = styled.div`
  .scroll-lock {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: 'theme ... logo ... burger';
    align-items: center;

    > button:nth-child(1) {
      grid-area: theme;
      justify-self: left;
    }

    > a:nth-child(2) {
      grid-area: logo;
      justify-self: center;
    }

    > button:nth-child(3) {
      grid-area: burger;
      justify-self: right;
    }
  }
`;
