import { createContext, useContext } from 'react';
import { ThemeProvider } from '@raiden-corp/rc-kit/contexts';
import { useThemeMode } from '@raiden-corp/rc-kit/hooks';
import { ThemeName } from '@raiden-corp/rc-kit/common';
import { themesMap } from '../../types/Theme';
import { ThemeInterface } from '../../themes';

interface IThemeContext {
  theme: keyof typeof ThemeName;
  themeMap: ThemeInterface;
  themeToggler: () => void;
  componentMounted: boolean;
}

interface IThemeProvider {
  children: React.ReactNode;
}

const ThemeCtx = createContext<IThemeContext>({} as IThemeContext);

export const ThemeContext = ({ children }: IThemeProvider) => {
  const { theme, themeToggler, componentMounted } = useThemeMode();
  const themeMode = themesMap[theme];

  return (
    <ThemeCtx.Provider value={{ theme, themeMap: themeMode, themeToggler, componentMounted }}>
      <ThemeProvider customTheme={themeMode}>{children}</ThemeProvider>
    </ThemeCtx.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeCtx);
