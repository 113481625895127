import {
  GA_ID_RDT_UK,
  GTM_RDT_FR,
  GTM_RDT_UK,
  HOSTNAME,
  HOTJAR_HJID_RDT_FR,
  HOTJAR_HJID_RDT_UK,
  HOTJAR_HJSV_RDT_FR,
  HOTJAR_HJSV_RDT_UK,
  TREATWELL_BUTTON_BASE_URL,
  TREATWELL_VENUE_ID,
} from '../config';

export enum Namespace {
  uk = 'globaluk',
  fr = 'globalfr',
}

export interface Domain {
  namespace: Namespace;
  hostname: string;
  treatwell: {
    baseUrl: string;
    placeId: string;
  };
  googleTagManagerId: string;
  hotjarId: number;
  hotjarSv: number;
  googleAnalyticId: string;
}

const uk: Domain = {
  namespace: Namespace.uk,
  hostname: HOSTNAME,
  treatwell: {
    baseUrl: TREATWELL_BUTTON_BASE_URL,
    placeId: TREATWELL_VENUE_ID,
  },
  googleTagManagerId: GTM_RDT_UK,
  googleAnalyticId: GA_ID_RDT_UK,
  hotjarId: HOTJAR_HJID_RDT_UK,
  hotjarSv: HOTJAR_HJSV_RDT_UK,
};

const fr: Domain = {
  namespace: Namespace.fr,
  hostname: HOSTNAME,
  treatwell: {
    baseUrl: TREATWELL_BUTTON_BASE_URL,
    placeId: TREATWELL_VENUE_ID,
  },
  googleTagManagerId: GTM_RDT_FR,
  googleAnalyticId: GA_ID_RDT_UK,
  hotjarId: HOTJAR_HJID_RDT_FR,
  hotjarSv: HOTJAR_HJSV_RDT_FR,
};

const toDomain = (site: string | undefined) => {
  if (!site) throw new Error(`site not found: ${site}`);
  if (site.endsWith(uk.hostname)) return uk;
  if (site.endsWith(fr.hostname)) return fr;
  else throw new Error(`Incorrect site ${site}`);
};

export const DOMAIN = toDomain(HOSTNAME);
