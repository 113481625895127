import { CookieConsentConfig, acceptedService } from 'vanilla-cookieconsent';
import i18n from '../../i18n/i18next';
import { CUSTOMER_SERVICE_EMAIL, isLocalhost } from '../../config';
import { initializeGoogleAnalytics } from './google/initializeGoogleAnalytics';
import { initializeGoogleTagManager } from './google/initializeGoogleTagManager';
import { initializeHotjarTrackingCode } from './hotjar/initializeHotjarTrackingCode';
import { Page } from '../../types/Page';

const getCookieConsentConfig = () => {
  const getRouteLink = (path: Page) => {
    if (isLocalhost()) return `http://localhost:${window.location.port}${path}${window.location.search}`;
    else return `${path}${window.location.search}`;
  };

  const copy = i18n.t('cookie.banner', {
    privacyPath: getRouteLink(Page.privacy),
    termsPath: getRouteLink(Page.terms),
    contactUsEmail: CUSTOMER_SERVICE_EMAIL,
    cookiesPolicyLink: getRouteLink(Page.cookies),
  });

  // const removeLastNFromArray = <T>(arr: T[], n: number) => arr.slice(0, -n);
  // USAGE: sections: removeLastNFromArray(copy.preferencesModal.sections, 3),

  const filteredSections = () =>
    Object.entries(copy.preferencesModal.sections)
      .filter(([_, { title }]) => !title.startsWith('Functionality') && !title.startsWith('Behavioural'))
      .map(([_, value]) => value);

  // https://cookieconsent.orestbida.com/reference/configuration-reference.html
  const config: CookieConsentConfig = {
    disablePageInteraction: false,
    cookie: {
      name: copy.cookieName,
      domain: window.location.hostname,
      path: '/',
      expiresAfterDays: acceptType => (acceptType === 'all' ? 365.25 : 182),
      sameSite: 'Lax',
    },
    onConsent: () => {
      if (acceptedService('ga', 'analytics')) {
        initializeGoogleAnalytics();
      }
      if (acceptedService('gtm', 'analytics')) {
        initializeGoogleTagManager();
      }
      if (acceptedService('hotjar', 'analytics')) {
        initializeHotjarTrackingCode();
      }
    },
    guiOptions: {
      consentModal: {
        layout: 'bar inline',
        position: 'bottom',
        equalWeightButtons: true,
        flipButtons: false,
      },
      preferencesModal: {
        layout: 'box',
        equalWeightButtons: true,
        flipButtons: false,
      },
    },
    categories: {
      necessary: {
        enabled: true,
        readOnly: true,
        services: {
          rdt: {
            label: '<strong>RDT</strong> uses cookies that enable basic functionality, which are essential for this cookie banner to work.',
          },
        },
      },
      analytics: {
        autoClear: {
          cookies: [
            {
              name: /^_ga/, // regex: match all cookies starting with '_ga'
            },
            {
              name: '_gid', // string: exact cookie name
            },
            {
              name: /^_gat/,
            },
            {
              name: /^_hj/,
            },
          ],
        },
        services: {
          ga: {
            label: 'Google Analytics',
          },
          gtm: {
            label: 'Google Tag Manager',
          },
          hotjar: {
            label: 'Hotjar',
          },
        },
      },
      functionality: {},
      marketing: {},
    },
    language: {
      default: 'en',
      autoDetect: 'browser',
      translations: {
        en: {
          ...copy,
          preferencesModal: {
            ...copy.preferencesModal,
            sections: filteredSections(),
          },
        },
        fr: {
          ...copy,
          preferencesModal: {
            ...copy.preferencesModal,
            sections: filteredSections(),
          },
        },
      },
    },
  };

  return config;
};

export default getCookieConsentConfig;
