import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-area: copyright;
  align-self: flex-end;
  padding: 1em;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[500] + '7a'};
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column-reverse;
  place-content: space-between;
  place-items: center;
  gap: 2em;

  > p {
    text-transform: uppercase;
  }

  > div {
    display: flex;
    flex-direction: column;
    place-content: center;
    place-items: center;
    gap: 1em;
  }

  ${({ theme }) => theme.breakpoints.md} {
    flex-direction: row;

    > div {
      flex-direction: row;
    }
  }
`;

export const Line = styled.div`
  margin-top: 0.5em;
  width: 150px;
  height: 1px;
  border-top: 1px solid ${({ theme }) => theme.colors.primary[500]};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
