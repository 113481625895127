import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { Reset } from 'styled-reset';
import { MotionContainer } from '@raiden-corp/rc-kit/hoc';
import BigSpinner from './components/BigSpinner';
import SpinnerOfDoom from './components/SpinnerOfDoom';
import { GlobalProvider } from './contexts/GlobalContext/GlobalContext.tsx';
import { ThemeContext as ThemeProvider } from './contexts/ThemeContext/ThemeContext.tsx';
import GlobalStyles from './styles/GlobalStyles';
import { I18nextProvider } from 'react-i18next';
import i18next from './i18n/i18next.ts';

const App = () => {
  return (
    <GlobalProvider>
      <ThemeProvider>
        <Reset />
        <GlobalStyles />
        <I18nextProvider i18n={i18next}>
          <MotionContainer>
            <Suspense fallback={<BigSpinner />}>
              <RouterProvider router={router} fallbackElement={<SpinnerOfDoom />} />
            </Suspense>
          </MotionContainer>
        </I18nextProvider>
      </ThemeProvider>
    </GlobalProvider>
  );
};

export default App;
