import enNs1 from '../assets/i18n/en.json';
import frNs1 from '../assets/i18n/fr.json';

const resources = {
  en: {
    ns1: enNs1,
  },
  fr: {
    ns1: frNs1,
  },
};

export default resources;
