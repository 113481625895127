import { forwardRef, memo } from 'react';
import { LinkWithQuery as Link, ILinkWithQuery } from '@raiden-corp/rc-kit/components';

const LinkWithQuery = forwardRef<HTMLAnchorElement, ILinkWithQuery>(({ children, ...rest }: ILinkWithQuery, ref) => {
  return (
    <Link {...rest} ref={ref}>
      {children}
    </Link>
  );
});

export default memo(LinkWithQuery);
export type { ILinkWithQuery };
