import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { AnimationProps } from 'framer-motion';
import { RegisterSWOptions, useRegisterSW } from 'virtual:pwa-register/react';
import { Button, IButton, Typography } from '@raiden-corp/rc-kit/components';
import * as S from './ReloadPrompt.styles';
import FramerPresence from '../../hoc/FramerPresence';

export interface IReloadPrompt {
  withDate?: boolean;
  debug?: boolean;
}

const intervalMS = 60 * 60 * 1000; // 1h
const autoHideIntervalMS = 20000; // 20s

const commonTransition: AnimationProps['transition'] = {
  type: 'spring',
  duration: 0.5,
};

const variants: AnimationProps['variants'] = {
  initial: {
    opacity: 0,
    x: 25,
    transition: commonTransition,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: commonTransition,
  },
  exit: {
    opacity: 0,
    x: 25,
    transition: commonTransition,
  },
};

const buttonStyles: IButton['style'] = {
  borderRadius: '8px',
  textTransform: 'uppercase',
  padding: '0.4em 0.7em',
  fontSize: '0.775em',
};

const onRegistered: RegisterSWOptions['onRegistered'] = registration => {
  registration && setInterval(async () => (console.log('Checking for sw update'), await registration.update()), intervalMS);
};

const onRegisteredSW: RegisterSWOptions['onRegisteredSW'] = (swUrl, registration) => {
  console.log(`Service Worker at: ${swUrl}`);
  registration &&
    setInterval(async () => {
      if (!(!registration.installing && navigator)) return;
      if ('connection' in navigator && !navigator.onLine) return;

      const res = await axios({
        url: swUrl,
        headers: {
          cache: 'no-store',
          'cache-control': 'no-cache',
        },
      });

      if (res.status === 200) await registration.update();
    }, intervalMS);
};

const onRegisterError: RegisterSWOptions['onRegisterError'] = error => console.log('SW registration error', error);

const ReloadPrompt = ({ withDate = false, debug = false }: IReloadPrompt) => {
  const { t } = useTranslation();
  const copy = t('components.reloadPrompt');
  const swc = useRegisterSW({ onRegistered, onRegisteredSW, onRegisterError });
  const {
    offlineReady: [offlineReady, setOfflineReady],
    needRefresh: [needRefresh, setNeedRefresh],
    updateServiceWorker,
  } = swc;

  const close = useCallback(() => {
    setOfflineReady(false);
    setNeedRefresh(false);
  }, [setNeedRefresh, setOfflineReady]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const hideOfflinePopup = async () =>
      await new Promise(
        resolve =>
          (timeoutId = setTimeout(() => {
            close();
            resolve(true);
          }, autoHideIntervalMS)),
      );

    if (offlineReady) hideOfflinePopup();

    return () => clearTimeout(timeoutId);
  }, [close, offlineReady]);

  return (
    <>
      <FramerPresence>
        {(offlineReady || needRefresh) && (
          <S.Wrapper variants={variants} initial="initial" animate="animate" exit="exit">
            <S.ToastContent>
              <S.Message>
                {offlineReady ? (
                  <Typography tag="p" variant="p6">
                    {copy.offlineReady.title}
                  </Typography>
                ) : (
                  <>
                    <Typography tag="p" variant="p6">
                      {copy.needRefresh.title}
                    </Typography>
                  </>
                )}
              </S.Message>
              <S.Buttons offlineReady={offlineReady}>
                {needRefresh && (
                  <Button style={buttonStyles} onClick={() => updateServiceWorker(true)}>
                    {copy.buttons.reload}
                  </Button>
                )}
                <Button style={buttonStyles} onClick={() => close()}>
                  {copy.buttons.close}
                </Button>
              </S.Buttons>
            </S.ToastContent>
            {withDate && (
              <S.Date>
                <Typography tag="p" variant="p6">
                  {dayjs(new Date().toISOString()).format('MMM D, YYYY')}
                </Typography>
              </S.Date>
            )}
          </S.Wrapper>
        )}
      </FramerPresence>
      <FramerPresence>
        {debug && (
          <S.Wrapper variants={variants} initial="initial" animate="animate" exit="exit">
            <S.DebugButtons>
              <Button style={buttonStyles} onClick={() => setNeedRefresh(true)}>
                Show Reload
              </Button>
              <Button style={buttonStyles} onClick={() => setNeedRefresh(false)}>
                Hide Reload
              </Button>
              <Button style={buttonStyles} onClick={() => setOfflineReady(true)}>
                Show Ready
              </Button>
              <Button style={buttonStyles} onClick={() => setOfflineReady(false)}>
                Hide Ready
              </Button>
            </S.DebugButtons>
          </S.Wrapper>
        )}
      </FramerPresence>
    </>
  );
};

export default ReloadPrompt;
