import TagManager from 'react-gtm-module';
import { DOMAIN } from '../../../types/Domain';
import { Namespace } from '../../../types/Domain';
import { shouldSendGaData } from './shouldSendGaData';

export function initializeGoogleTagManager() {
  if ((DOMAIN.googleTagManagerId && shouldSendGaData()) || DOMAIN.namespace === Namespace.uk) {
    TagManager.initialize({
      gtmId: DOMAIN.googleTagManagerId,
    });
  }
}
