import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  display: flex;
  flex-flow: column;
  gap: 0.6em;
  margin: 0 0.5em 0.5em;

  @media all and (display-mode: standalone) {
    margin: 0 1em 2em;
  }

  ${({ theme }) => theme.breakpoints.md} {
    width: 30em;
    margin: 0 1em 1em auto;
  }
`;
