import { getQueryStringParam, sleep } from '@raiden-corp/rc-kit/utils';
import { isProduction } from '../../../config';
import { DOMAIN } from '../../../types/Domain';
import { Namespace } from '../../../types/Domain';
import { injectGoogleAnalytics } from './injectGoogleAnalytics';

export async function initializeGoogleAnalytics() {
  if (isProduction() && DOMAIN.namespace === Namespace.uk) {
    injectGoogleAnalytics(DOMAIN.googleAnalyticId);
  }
  await sleep(500);
  saveDebugStatus(getQueryStringParam('sendgadata'));
}

export function saveDebugStatus(urlParam: string | undefined): void {
  if (urlParam) {
    localStorage.setItem('sendgadata', 'true');
  }
}
