import { getQueryStringParam, sleep } from '@raiden-corp/rc-kit/utils';
import { isProduction } from '../../../config';
import { DOMAIN } from '../../../types/Domain';
import { Namespace } from '../../../types/Domain';
import { injectHjTrackingCode } from './injectHjTrackingCode';

export async function initializeHotjarTrackingCode() {
  if (isProduction() && DOMAIN.namespace === Namespace.uk) {
    injectHjTrackingCode(DOMAIN.hotjarId, DOMAIN.hotjarSv);
  }
  await sleep(500);
  saveDebugStatus(getQueryStringParam('sendhjdata'));
}

export function saveDebugStatus(urlParam: string | undefined): void {
  if (urlParam) {
    localStorage.setItem('sendhjdata', 'true');
  }
}
