import * as S from './Notifications.styles';
import { useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReloadPrompt } from '../ReloadPrompt';
import FramerPresence from '../../hoc/FramerPresence';
import { tryCreateModalRoot } from '../../utils/generic/tryCreateModalRoot';

const Notifications = () => {
  const [showNotifications, setShowNotifications] = useState(false);
  const modalRootEl = useRef<Element>();

  useLayoutEffect(() => {
    modalRootEl.current = tryCreateModalRoot('notifications-root');
    if (modalRootEl.current) setShowNotifications(true);
  }, []);

  const childNode = (
    <FramerPresence>
      <S.Wrapper>
        <ReloadPrompt />
      </S.Wrapper>
    </FramerPresence>
  );
  return modalRootEl.current && showNotifications ? createPortal(childNode, modalRootEl.current) : <></>;
};

export default Notifications;
