import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme }) => css`
    position: relative;
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    color: ${theme.mode === 'light' ? '#eee4d2' : '#121212'};
    padding: ${theme.body.padding};

    > div {
      width: 100%;
      height: 100%;
      display: flex;

      &:first-of-type {
        place-items: center;
        place-content: center;
      }

      &:last-of-type {
        flex-flow: column;
        place-content: center;

        h1,
        h2,
        h3 {
          padding-bottom: 1em;
        }

        ul {
          padding-left: 1.5em;
          padding-bottom: 1.5em;

          li::marker {
            color: ${theme.colors.primary[500]};
          }
        }

        button {
          width: fit-content;
        }

        .debug {
          background-color: rgb(234 179 8 / 16%);
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 1em;
          line-height: 24px;
          margin: 16px 0;
          font-size: 14px;
          white-space: pre-wrap;
          color: ${theme.colors.primary[500]};
          font-family: ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

          > code {
            color: #f9b44e;
            background-color: rgb(234 179 8 / 16%);
            padding: 3px 6px;
            border-radius: 4px;
            font-size: 0.875em;
            transition:
              color 0.25s,
              background-color 0.5s;
          }

          > div {
            position: relative;
            background-color: #161618;
            overflow-x: auto;
            transition: background-color 0.5s;
            margin: 8px 0;
            border-radius: 8px;

            > pre {
              position: relative;
              z-index: 1;
              margin: 0;
              padding: 20px 0;
              background: transparent;
              overflow-x: auto;

              > code {
                font-weight: 400;
                background-color: transparent;
                display: block;
                padding: 0 24px;
                width: fit-content;
                min-width: 100%;
                line-height: 1.7;
                font-size: 0.875em;
                color: rgb(235 235 245 / 60%);
                transition: color 0.5s;
              }
            }
          }
        }
      }
    }

    ${theme.breakpoints.lg} {
      grid-template-columns: 0.5fr 1fr;
    }
  `,
);
