import { Variants } from 'framer-motion';

export interface IMenuLogo<T extends boolean> {
  isOpen: T;
}

export const menuLogoVariants: Variants = {
  hidden: { pointerEvents: 'none' },
  visible: { pointerEvents: 'auto' },
};
