import { RefObject, createRef } from 'react';
import { RouteObject } from 'react-router-dom';
import { Layout } from './hoc/Layout';
import { Page, headerPageMapping, pageMapping } from './types/Page';
import { RootErrorBoundary, ProjectErrorBoundary } from './components/ErrorBoundary';
import { sentryCreateBrowserRouter } from './utils/sentry/initializeSentry';
import { LazyReact } from './hoc/LazyReact';

const Home = LazyReact(() => import('./screens/Home'));
const About = LazyReact(() => import('./screens/About'));
const SiteMap = LazyReact(() => import('./screens/SiteMap'));
const NotFound = LazyReact(() => import('./screens/NotFound'));
const BookingCancellationPolicy = LazyReact(() => import('./screens/BookingCancellationPolicy/BookingCancellationPolicy'));
const Treatment = LazyReact(() => import('./screens/Treatment'));
const Treatments = LazyReact(() => import('./screens/Treatments'));
const GetInTouch = LazyReact(() => import('./screens/GetInTouch'));
const Packages = LazyReact(() => import('./screens/Packages'));
const CookiePolicy = LazyReact(() => import('./screens/CookiePolicy'));
const PrivacyPolicy = LazyReact(() => import('./screens/PrivacyPolicy'));
const TermsAndConditions = LazyReact(() => import('./screens/TermsAndConditions'));

export type IRoutes = RouteObject & {
  name: string;
  title: string;
  nodeRef: RefObject<HTMLElement>;
};

const routes: IRoutes[] = [
  {
    index: true,
    path: Page.home,
    element: <Home />,
    title: pageMapping[Page.home],
    name: headerPageMapping[Page.home],
    nodeRef: createRef(),
  },
  {
    path: Page.about,
    element: <About />,
    title: pageMapping[Page.about],
    name: headerPageMapping[Page.about],
    nodeRef: createRef(),
  },
  {
    path: Page.treatments,
    element: <Treatments />,
    title: pageMapping[Page.treatments],
    name: headerPageMapping[Page.treatments],
    nodeRef: createRef(),
  },
  {
    path: Page.packages,
    element: <Packages />,
    title: pageMapping[Page.packages],
    name: headerPageMapping[Page.packages],
    nodeRef: createRef(),
  },
  {
    path: Page.treatment,
    element: <Treatment />,
    title: pageMapping[Page.treatment],
    name: headerPageMapping[Page.treatment],
    nodeRef: createRef(),
  },
  {
    path: Page.bookingCancellationPolicy,
    element: <BookingCancellationPolicy />,
    title: pageMapping[Page.bookingCancellationPolicy],
    name: headerPageMapping[Page.bookingCancellationPolicy],
    nodeRef: createRef(),
  },
  {
    path: Page.notFound,
    element: <NotFound />,
    title: pageMapping[Page.notFound],
    name: headerPageMapping[Page.notFound],
    nodeRef: createRef(),
  },
  {
    path: Page.getInTouch,
    element: <GetInTouch />,
    title: pageMapping[Page.getInTouch],
    name: headerPageMapping[Page.getInTouch],
    nodeRef: createRef(),
  },
  {
    path: Page.siteMap,
    element: <SiteMap />,
    title: pageMapping[Page.siteMap],
    name: headerPageMapping[Page.siteMap],
    nodeRef: createRef(),
  },
  {
    path: Page.cookies,
    element: <CookiePolicy />,
    title: pageMapping[Page.cookies],
    name: headerPageMapping[Page.cookies],
    nodeRef: createRef(),
  },
  {
    path: Page.privacy,
    element: <PrivacyPolicy />,
    title: pageMapping[Page.privacy],
    name: headerPageMapping[Page.privacy],
    nodeRef: createRef(),
  },
  {
    path: Page.terms,
    element: <TermsAndConditions />,
    title: pageMapping[Page.terms],
    name: headerPageMapping[Page.terms],
    nodeRef: createRef(),
  },
];

const router = sentryCreateBrowserRouter([
  {
    path: Page.home,
    element: <Layout />,
    errorElement: <RootErrorBoundary />,
    children: routes.map(route => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
      errorElement: <ProjectErrorBoundary />,
    })),
  },
]);

export default router;
export { routes };
