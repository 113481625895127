export function appendScript(url: string, delay = 0, callback?: () => void) {
  setTimeout(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    if (callback) {
      script.onload = callback;
    }

    document.head.appendChild(script);
  }, delay);
}

export function appendScriptText(text: string, delay = 0, callback?: () => void) {
  setTimeout(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = text;

    if (callback) {
      script.onload = callback;
    }

    document.head.appendChild(script);
  }, delay);
}
