import axios, { AxiosInstance } from 'axios';
import { API_BASE_URL } from '../config';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  timeout: 5000,
  headers: {
    'Content-type': 'application/json',
  },
});

export default axiosInstance;
