import { Dispatch, SetStateAction } from 'react';
import { Cycle, Variants } from 'framer-motion';

export interface IMorphingSidePortal {
  isOpen: boolean;
  setAnimating: Dispatch<SetStateAction<boolean>>;
  toggleOpen: Cycle;
}

export interface IMorphingSidePortalRef {
  toggleMenu: () => void;
}

export const sidePortalVariants: Variants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
    transition: {
      opacity: { delay: 2 },
    },
  },
  exit: {
    opacity: 0,
    transition: {
      opacity: { delay: 2 },
    },
  },
};

export const globalMenuVariants: Variants = {
  open: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: -1,
      delayChildren: 1.5,
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
  exit: {
    transition: {
      staggerChildren: 0.1,
      staggerDirection: 1,
    },
  },
};

export const globalMenuItemVariants: Variants = {
  open: () => ({
    opacity: 1,
    y: 0,
    rotate: 0,
  }),
  closed: (i: number) => ({
    opacity: 0,
    y: -100,
    rotate: isEven(i) ? 10 : -10,
  }),
  exit: (i: number) => ({
    opacity: 0,
    y: -100,
    rotate: isEven(i) ? 10 : -10,
  }),
};

export const reducedGlobalMenuItemVariants: Variants = {
  open: () => ({
    opacity: 1,
  }),
  closed: () => ({
    opacity: 0,
  }),
  exit: () => ({
    opacity: 0,
  }),
};

const isEven = (n: number) => n % 2 === 0;

export const commonTransition = {
  ease: 'easeIn',
  duration: 0.3,
};
