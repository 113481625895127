import React, { useLayoutEffect } from 'react';
import { NavigationType, useLocation, useNavigationType, useOutlet, useParams } from 'react-router-dom';
import { MotionProps, m } from 'framer-motion';
import { sleep } from '@raiden-corp/rc-kit/utils';
import { FocusLock } from '@raiden-corp/rc-kit/hoc';
import { useDocumentTitle } from '@raiden-corp/rc-kit/hooks';
import { routes } from '../../router';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Notifications } from '../../components/Notifications';
import FramerPresence from '../FramerPresence';
import { mainVariants } from './Layout.constants';
import { useThemeContext } from '../../contexts/ThemeContext/ThemeContext';
import { PAGES, Page, TreatmentType, pageMapping, treatmentPageMapping } from '../../types/Page';

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

export const delayResolvedScrollToTop = async (delay = 450) =>
  await new Promise(resolve => {
    return setTimeout(() => {
      document.documentElement.scrollTo({ top: 0, behavior: 'instant' });
      resolve(true);
    }, delay);
  });

const Layout = () => {
  const outlet = useOutlet();
  const isPop = useBackButton();
  const { pathname } = useLocation();
  const { treatmentId } = useParams();
  const { theme } = useThemeContext();

  const { nodeRef } = routes.find(({ path }) => path === pathname) ?? {};

  const getNotFoundTitle = (pathname: string) => (PAGES.includes(pathname as Page) ? pathname : Page.notFound) as Page;
  useDocumentTitle(typeof treatmentId !== 'undefined' ? treatmentPageMapping[treatmentId as TreatmentType] : pageMapping[getNotFoundTitle(pathname)]);

  const mainProps: React.RefAttributes<HTMLElement> & MotionProps = {
    ref: nodeRef,
    key: pathname,
    ...mainVariants,
  };

  const scrollToTop = async () => {
    await sleep(450);
    document.documentElement.scrollTo({ top: 0, behavior: 'instant' });
  };

  useLayoutEffect(() => {
    theme === 'dark' ? document.documentElement.classList.add('cc--darkmode') : document.documentElement.classList.remove('cc--darkmode');
  }, [theme]);

  useLayoutEffect(() => {
    scrollToTop();
  }, [pathname, isPop]);

  return (
    <>
      <FocusLock group="layout" className="layout-focus-lock" shards={[nodeRef as unknown as HTMLElement]} whiteList={node => (document.getElementById('root') as HTMLElement).contains(node)}>
        <Header />
        <FramerPresence>
          <m.main {...mainProps}>{outlet}</m.main>
        </FramerPresence>
        <Footer />
      </FocusLock>
      <Notifications />
    </>
  );
};

export default Layout;
