import * as S from './ToggleTheme.styles';
import { ThemeSwitch } from '@raiden-corp/rc-kit/components';
import { useThemeContext } from '../../contexts/ThemeContext/ThemeContext';

const ToggleTheme = () => {
  const { theme, themeToggler } = useThemeContext();

  const buttonProps: React.ButtonHTMLAttributes<HTMLButtonElement> & { 'data-testid': string } = {
    title: 'Theme Button',
    'aria-label': 'Theme Button',
    'data-testid': 'toggle-theme-button',
    onClick: themeToggler,
  };

  return (
    <S.Container {...buttonProps}>
      <ThemeSwitch theme={theme} />
    </S.Container>
  );
};

export default ToggleTheme;
