import type React from 'react';
import type { ConfigOptions } from '@axe-core/react';

const accessibilityConfig: ConfigOptions = {};

/**
 * Tests the application with the axe-core accessibility testing library.
 * Results will show in the DevTools console.
 * @see https://github.com/dequelabs/axe-core
 * @param App - Entry point React instance
 * @param config - Configures the format of the data used by axe. Can be used to add new rules.
 */
export const reportAccessibility = async (App: typeof React, config?: ConfigOptions): Promise<void> => {
  if (process.env.NODE_ENV !== 'production') {
    const Axe = await import('@axe-core/react');
    const ReactDOM = await import('react-dom/client');
    Axe.default(App, ReactDOM, 1000, config || accessibilityConfig);
  }
};

export default reportAccessibility;
