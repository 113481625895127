import * as S from './NavLinks.styles';
import { routes } from '../../../router';
import { Page, footerExcludedCompanyRoutes, footerExcludedMainRoutes } from '../../../types/Page';
import { Typography } from '@raiden-corp/rc-kit/components';
import LinkWithQuery from '../../LinkWithQuery';
import { useTranslation } from 'react-i18next';

const NavLinks = () => {
  const { t } = useTranslation();
  const copy = t('components.footer.navLinks');

  return (
    <S.Wrapper data-testid="footer-nav-links">
      <div className="rdt">
        <Typography tag="h2" variant="d3" weight="900">
          {copy.titles[0]}
        </Typography>
        <ul>
          {routes
            .filter(r => !footerExcludedMainRoutes.includes(r.path as Page))
            .map(
              (route, i) =>
                route.path && (
                  <li key={i}>
                    <Typography tag="span" variant="span">
                      <LinkWithQuery to={route.path}>{route.name}</LinkWithQuery>
                    </Typography>
                  </li>
                ),
            )}
        </ul>
      </div>
      <div className="company">
        <Typography tag="h2" variant="d3" weight="900">
          {copy.titles[1]}
        </Typography>
        <ul>
          {routes
            .filter(r => !footerExcludedCompanyRoutes.includes(r.path as Page))
            .map(
              (route, i) =>
                route.path && (
                  <li key={i}>
                    <Typography tag="span" variant="span">
                      <LinkWithQuery to={route.path}>{route.name}</LinkWithQuery>
                    </Typography>
                  </li>
                ),
            )}
        </ul>
      </div>
      <div className="address">
        <Typography tag="h2" variant="d3" weight="900">
          {copy.titles[2]}
        </Typography>
        <ul>
          {copy.address.p.map((p, i) => (
            <li key={i}>
              <Typography key={i} tag="span" variant="span">
                {p}
              </Typography>
            </li>
          ))}
        </ul>
      </div>
    </S.Wrapper>
  );
};

export default NavLinks;
