import styled from 'styled-components';

export const Wrapper = styled.div`
  grid-area: navlinks;
  display: grid;

  .d3 {
    font-size: 1.75em;
    text-transform: uppercase;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1.5em 0;

    ul {
      list-style-type: none;
    }

    &:not(:last-child) {
      ul {
        display: flex;
        flex-direction: column;
        gap: 0.5em 0;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.xs} {
    grid-gap: 2em;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.md} {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-around;
  }
`;
