import { useRef, forwardRef, useImperativeHandle, KeyboardEvent } from 'react';
import { m, useReducedMotion } from 'framer-motion';
import * as S from './MorphingSidePortal.styles';
import { Portal } from '@raiden-corp/rc-kit/components';
import { routes } from '../../router';
import { ease } from '../../utils/object/ease';
import FramerPresence from '../../hoc/FramerPresence';
import { Page, morphingSidePortalRoutes } from '../../types/Page';
import { sidePortalVariants, IMorphingSidePortal, IMorphingSidePortalRef, globalMenuVariants, globalMenuItemVariants, reducedGlobalMenuItemVariants, commonTransition } from './MorphingSidePortal';
import { FocusLock } from '@raiden-corp/rc-kit/hoc';
import { useAnimationFlag } from '@raiden-corp/rc-kit/hooks';

const MorphingSidePortal = forwardRef<IMorphingSidePortalRef, IMorphingSidePortal>(({ isOpen, toggleOpen, setAnimating }: IMorphingSidePortal, ref) => {
  const pathRef = useRef<SVGPathElement[]>([]);
  const delayPointsArray = useRef<number[]>([]);
  const localRef = useRef<HTMLDivElement>(null);

  let timeStart = Date.now();
  const numPoints = 4;
  const duration = 1600;
  const delayPointsMax = 360;
  const delayPerPath = 120;
  const motionPaths = Array.from({ length: 4 }, (_, i) => i);

  const prefersReducedMotion = useReducedMotion();
  const { isAnimationsDisabled } = useAnimationFlag(prefersReducedMotion);

  useImperativeHandle(ref, () => ({
    toggleMenu() {
      setAnimating(true);
      const range = Math.random() * Math.PI * 2;

      for (let i = 0; i < numPoints; i++) {
        const radian = (i / (numPoints - 1)) * Math.PI * 2;
        delayPointsArray.current[i] = ((Math.sin(radian + range) + 1) / 2) * delayPointsMax;
      }

      if (isOpen === false) openMenu();
      else closeMenu();
    },
  }));

  const updatePath = (time: number) => {
    const points = [];

    for (let i = 0; i < numPoints; i++) {
      points[i] = ease.cubicInOut(Math.min(Math.max(time - delayPointsArray.current[i], 0) / duration, 1)) * 100;
    }

    let str = '';

    str += isOpen === true ? `M 0 0 V ${points[0]} ` : `M 0 ${points[0]} `;

    for (let i = 0; i < numPoints - 1; i++) {
      const p = ((i + 1) / (numPoints - 1)) * 100;
      const cp = p - ((1 / (numPoints - 1)) * 100) / 2;
      str += `C ${cp} ${points[i]} ${cp} ${points[i + 1]} ${p} ${points[i + 1]} `;
    }

    str += isOpen === true ? `V 0 H 0` : `V 100 H 0`;

    return str;
  };

  const render = () => {
    if (isOpen === true) {
      for (let i = 0; i < pathRef.current.length; i++) {
        pathRef.current[i].setAttribute('d', updatePath(Date.now() - (timeStart + delayPerPath * i)));
      }
    } else {
      for (let i = 0; i < pathRef.current.length; i++) {
        pathRef.current[i].setAttribute('d', updatePath(Date.now() - (timeStart + delayPerPath * (pathRef.current.length - i - 1))));
      }
    }
  };

  const renderLoop = () => {
    if (!isAnimationsDisabled) {
      render();
    }
    if (Date.now() - timeStart < duration + delayPerPath * (pathRef.current.length - 1) + delayPointsMax) {
      requestAnimationFrame(() => renderLoop());
    } else {
      setAnimating(false);
    }
  };

  const openMenu = () => {
    toggleOpen(1);
    timeStart = Date.now();
    renderLoop();
  };

  const closeMenu = () => {
    toggleOpen(0);
    timeStart = Date.now();
    renderLoop();
  };

  const refEL = (refEl: SVGPathElement | null, index: number) => {
    if (refEl !== null) return (pathRef.current[index] = refEl);
    return refEl;
  };

  const handleKeyPress = ({ key }: KeyboardEvent) => key === 'Enter' && toggleOpen();

  return (
    <Portal>
      <FramerPresence>
        {isOpen && (
          <FocusLock group="navigation" disabled={!isOpen} returnFocus>
            <S.Container key="morphing-side-portal" data-testid="morphing-side-portal" variants={!isAnimationsDisabled ? sidePortalVariants : undefined} initial="closed" animate="open" exit="exit">
              <S.Menu>
                <S.MotionLinkItems ref={localRef} variants={!isAnimationsDisabled ? globalMenuVariants : undefined} initial="closed" animate="open" exit="exit">
                  {routes
                    .filter(r => !morphingSidePortalRoutes.includes(r.path as Page))
                    .map(
                      (route, i) =>
                        route.path && (
                          <S.MotionLinkItem
                            to={route.path}
                            key={i}
                            custom={i}
                            variants={isAnimationsDisabled ? reducedGlobalMenuItemVariants : globalMenuItemVariants}
                            onClick={() => toggleOpen()}
                            onKeyPress={handleKeyPress}
                            transition={commonTransition}
                            classes={`motion-link${i}`}
                          >
                            {route.name}
                          </S.MotionLinkItem>
                        ),
                    )}
                </S.MotionLinkItems>
              </S.Menu>
              <S.Svg className={`${isOpen ? 'is-opened' : ''}`} viewBox="0 0 100 100" preserveAspectRatio="none">
                {motionPaths.map((_, i) => (
                  <m.path key={i} custom={i} ref={refEl => refEL(refEl, i)} />
                ))}
              </S.Svg>
            </S.Container>
          </FocusLock>
        )}
      </FramerPresence>
    </Portal>
  );
});

export default MorphingSidePortal;
