import { useTranslation } from 'react-i18next';
import * as S from './Copyright.styles';
import { Typography } from '@raiden-corp/rc-kit/components';
import { routes } from '../../../router';
import { Fragment } from 'react';
import { Page, copyrightRoutes } from '../../../types/Page';
import LinkWithQuery from '../../LinkWithQuery';

const Copyright = () => {
  const { t } = useTranslation();
  const copy = t('components.footer.copyright');

  return (
    <S.Wrapper data-testid="copyright">
      <S.Container>
        <Typography variant="p6">{copy.p[0]}</Typography>
        <div>
          {routes
            .filter(r => copyrightRoutes.includes(r.path as Page))
            .map((route, index) => (
              <Fragment key={index}>
                <Typography tag="p" variant="p6">
                  <LinkWithQuery to={route.path as string}>{route.name}</LinkWithQuery>
                </Typography>
              </Fragment>
            ))}
        </div>
      </S.Container>
    </S.Wrapper>
  );
};

export default Copyright;
