import { css } from 'styled-components';

export const lightTheme = css`
  --cc-bg: var(--primary-500);
  --cc-primary-color: var(--secondary-500);
  --cc-secondary-color: var(--cc-primary-color);

  --cc-btn-primary-bg: var(--cc-primary-color);
  --cc-btn-primary-color: var(--cc-bg);
  --cc-btn-primary-border-color: var(--cc-bg);
  --cc-btn-primary-hover-bg: var(--secondary-400);
  --cc-btn-primary-hover-color: var(--cc-bg);
  --cc-btn-primary-hover-border-color: var(--cc-btn-primary-border-color);

  --cc-btn-secondary-bg: var(--cc-bg);
  --cc-btn-secondary-color: var(--cc-primary-color);
  --cc-btn-secondary-border-color: var(--cc-primary-color);
  --cc-btn-secondary-hover-bg: var(--secondary-200);
  --cc-btn-secondary-hover-color: var(--cc-primary-color);
  --cc-btn-secondary-hover-border-color: var(--cc-primary-color);

  --cc-separator-border-color: var(--cc-primary-color);

  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-off-bg: var(--secondary-300);
  --cc-toggle-on-knob-bg: var(--cc-btn-primary-color);
  --cc-toggle-off-knob-bg: var(--cc-btn-primary-color);

  --cc-toggle-enabled-icon-color: var(--cc-btn-primary-color);
  --cc-toggle-disabled-icon-color: var(--cc-btn-primary-color);

  --cc-toggle-readonly-bg: var(--secondary-400);
  --cc-toggle-readonly-knob-bg: var(--secondary-200);
  --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);

  --cc-cookie-category-block-bg: var(--primary-300);
  --cc-cookie-category-block-border: var(--cc-toggle-off-bg);
  --cc-cookie-category-block-hover-bg: var(--cc-toggle-readonly-knob-bg);
  --cc-cookie-category-block-hover-border: var(--cc-toggle-off-bg);
  --cc-cookie-category-expanded-block-bg: transparent;
  --cc-cookie-category-expanded-block-hover-bg: var(--cc-toggle-readonly-bg);

  --cc-overlay-bg: rgba(238, 228, 210, 0.65);
  --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
  --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);

  --cc-footer-bg: var(--primary-500);
  --cc-footer-color: var(--secondary-500);
  --cc-footer-border-color: var(--secondary-400);

  #cc-main {
    .cm {
      box-shadow:
        0 1px 6px var(--box-shadow-color-light-2),
        0 1px 6px var(--box-shadow-color-light-1);
    }

    .cm__title {
      padding: 0 1.1em;
    }

    .cm__btn {
      box-shadow:
        0 1px 3px var(--box-shadow-color-light-2),
        0 1px 3px var(--box-shadow-color-light-1);
    }

    .pm__badge {
      background: var(--secondary-500);
      color: var(--primary-500);
    }

    .pm__section-arrow svg {
      stroke: var(--primary-500);
    }

    .pm__section-title-wrapper {
    }
  }

  .show--preferences {
    .pm {
      background: var(--primary-500);
    }
  }
`;

export const darkTheme = css`
  .cc--darkmode {
    --cc-bg: var(--black-light-100);
    --cc-primary-color: var(--primary-500);
    --cc-secondary-color: var(--cc-primary-color);

    --cc-btn-primary-bg: var(--cc-primary-color);
    --cc-btn-primary-color: var(--cc-bg);
    --cc-btn-primary-border-color: var(--secondary-500);
    --cc-btn-primary-hover-bg: var(--secondary-100);
    --cc-btn-primary-hover-color: var(--cc-btn-primary-border-color);
    --cc-btn-primary-hover-border-color: var(--cc-btn-primary-border-color);

    --cc-btn-secondary-bg: var(--cc-bg);
    --cc-btn-secondary-color: var(--cc-primary-color);
    --cc-btn-secondary-border-color: var(--cc-primary-color);
    --cc-btn-secondary-hover-bg: var(--secondary-400);
    --cc-btn-secondary-hover-color: var(--cc-primary-color);
    --cc-btn-secondary-hover-border-color: var(--cc-primary-color);

    --cc-separator-border-color: var(--cc-primary-color);

    --cc-toggle-on-bg: var(--cc-btn-primary-bg);
    --cc-toggle-off-bg: var(--secondary-300);
    --cc-toggle-on-knob-bg: var(--cc-btn-primary-color);
    --cc-toggle-off-knob-bg: var(--cc-btn-primary-color);

    --cc-toggle-enabled-icon-color: var(--cc-btn-primary-color);
    --cc-toggle-disabled-icon-color: var(--cc-btn-primary-color);

    --cc-toggle-readonly-bg: var(--cc-btn-secondary-hover-bg);
    --cc-toggle-readonly-knob-bg: var(--cc-btn-primary-border-color);
    --cc-toggle-readonly-knob-icon-color: var(--cc-toggle-readonly-bg);

    --cc-cookie-category-block-bg: var(--cc-btn-primary-border-color);
    --cc-cookie-category-block-border: var(--cc-primary-color);
    --cc-cookie-category-block-hover-bg: var(--secondary-200);
    --cc-cookie-category-block-hover-border: var(--cc-primary-color);
    --cc-cookie-category-expanded-block-bg: transparent;
    --cc-cookie-category-expanded-block-hover-bg: var(--cc-toggle-readonly-bg);

    --cc-overlay-bg: rgba(0, 0, 0, 0.65);
    --cc-webkit-scrollbar-bg: var(--cc-section-category-border);
    --cc-webkit-scrollbar-hover-bg: var(--cc-btn-primary-hover-bg);

    --cc-footer-bg: var(--cc-bg);
    --cc-footer-color: var(--cc-primary-color);
    --cc-footer-border-color: var(--secondary-400);

    #cc-main {
      .cm {
        box-shadow:
          0 1px 3px var(--box-shadow-color-dark-2),
          0 1px 3px var(--box-shadow-color-dark-1);
      }

      .cm__btn {
        box-shadow:
          0 1px 3px var(--box-shadow-color-dark-2),
          0 1px 3px var(--box-shadow-color-dark-1);
      }

      .pm__badge {
        background: var(--primary-500);
        color: var(--secondary-500);
      }

      .pm {
        background: var(--black-light-100);
      }
    }
  }
`;
