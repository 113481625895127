import { useRef, useState, useCallback, useLayoutEffect } from 'react';
import { useCycle, useReducedMotion } from 'framer-motion';
import * as S from './Header.styles';
import MenuLogo from '../MenuLogo';
import { BurgerMenu } from '@raiden-corp/rc-kit/components';
import ToggleTheme from '../ToggleTheme';
import { initialVariants, mobileVariants, standardVariants } from './Header';
import MorphingSidePortal from '../MorphingSidePortal';
import { FocusLock, ScrollLock } from '@raiden-corp/rc-kit/hoc';
import FramerPresence from '../../hoc/FramerPresence';
import { IMorphingSidePortalRef } from '../MorphingSidePortal/MorphingSidePortal';
import { isHomePage } from '../../utils/page/getCurrentPage';
import { useMobileDetect } from '@raiden-corp/rc-kit/hooks';
import { useAnimationFlag } from '@raiden-corp/rc-kit/hooks';

const Header = () => {
  const { isMobile } = useMobileDetect();
  const [isOpen, toggleOpen] = useCycle(false, true);
  const [animating, setAnimating] = useState<boolean>(false);

  const sidePortalRef = useRef<IMorphingSidePortalRef>(null);

  const prefersReducedMotion = useReducedMotion();
  const { isAnimationsDisabled } = useAnimationFlag(prefersReducedMotion);
  const animationVariants = isHomePage() ? initialVariants : isMobile() ? mobileVariants : standardVariants;

  const toggle = () => {
    if (animating) return false;
    sidePortalRef.current?.toggleMenu();
  };

  const handleWindowKeyDown = useCallback(({ key }: KeyboardEvent) => isOpen && key === 'Escape' && toggleOpen(), [toggleOpen, isOpen]);

  useLayoutEffect(() => {
    window.addEventListener('keydown', handleWindowKeyDown);
    return () => window.removeEventListener('keydown', handleWindowKeyDown);
  }, [handleWindowKeyDown]);

  return (
    <FramerPresence>
      <S.Wrapper variants={!isAnimationsDisabled ? animationVariants : undefined} initial="hidden" animate="visible" data-testid="header">
        <S.Container>
          <FocusLock group="navigation" disabled={!isOpen}>
            <ScrollLock enabled={isOpen} className="scroll-lock">
              <ToggleTheme />
              <MenuLogo isOpen={isOpen} />
              <BurgerMenu isOpen={isOpen} onToggle={toggle} />
              <MorphingSidePortal ref={sidePortalRef} isOpen={isOpen} toggleOpen={toggleOpen} setAnimating={setAnimating} />
            </ScrollLock>
          </FocusLock>
        </S.Container>
      </S.Wrapper>
    </FramerPresence>
  );
};

export default Header;
