import { persistQueryStringParams } from '@raiden-corp/rc-kit/utils';
import { createContext, useContext, useState } from 'react';
import { queryStringParamsToPreserve } from '../../types/LocalStorageItem';

export interface IGlobalContext {
  isLoading: boolean;
}

export const defaultValues: IGlobalContext = {
  isLoading: false,
};

export const GlobalCtx = createContext<IGlobalContext>(defaultValues);

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, _] = useState<boolean>(false);

  const state: IGlobalContext = {
    isLoading,
  };

  persistQueryStringParams(queryStringParamsToPreserve);

  return <GlobalCtx.Provider value={state}>{children}</GlobalCtx.Provider>;
};

export const useGlobalContext = () => useContext(GlobalCtx);
